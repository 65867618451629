// Affichage Date & Heure
const date = new Date();

const current_time = `${date.getHours()}:${date.getMinutes()}`;
// raccourci d’écriture
document.getElementById('clock').innerHTML = current_time;
// function $(id){return document.getElementById(id)}

function hms() {
  const today = new Date();
  const date = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
  const hrs = today.getHours(); const mns = today.getMinutes();
  const time = `${hrs < 10 ? `0${hrs}` : hrs}:${mns < 10 ? `0${mns}` : mns}`;
  const str = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>${time}`;
  document.getElementById('clock').innerHTML = `${str}<br>` + `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>${date}`;
  setTimeout(hms, 60000);// réécriture toutes les 60000 millisecondes
}
hms();// lancement de la fonction
// if (!window.Intl) {
//   var polyfill = document.createElement("script");
//   polyfill.setAttribute("src", "https://cdn.polyfill.io/v2/polyfill.min.js?features=Intl.~locale.en");
//   document.head.appendChild(script);
